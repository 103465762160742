import ThemeType from "./interfaces/ThemeType";

const Theme: ThemeType = {
    mainColor: '#27C6BC',
    accentColor: '#FED249',
    subColor: '#78CC9B',
    textColor: '#3C3C3C',
    textGrayColor: '#3c3c3c',
    disableColor: '#C1C1C1',
    whiteColor: '#FFFFFF',
    linkColor: '#038bd2',
    borderColor: '#E1E1E1',
    lightColor: '#EFFEFA',
    pieColor: '#9BF4EE',
    jtcaColor: '#FEF7A7',
    jhmaColor: '#F7F8F3',
    reserveButtonColor: '#EE84AB',
    lightBackgroundColor: '#EEFFFA',
    importantBackgroundColor: '#FEF2A3',
    noticeIconColor: '#E8FDF2',
    titleLetterSpace: 1.33389,
    bodyLetterSpace: 0.83333,
    dangerColor: '#fa5e68',
    dangerBackgroundColor: '#FFF5F4',
    descriptionColor: '#47ba95',
    welcomeBackgroundColor: '#CDF0E1',
};

export default Theme;
