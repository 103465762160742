import React, {useCallback, useEffect} from 'react';
import styled from "styled-components";
import DrawerItem from "../../parts/MainDrawer/DrawerItem";
import GuideButton from "../../parts/MainDrawer/GuideButton";
import ContactButton from "../../parts/MainDrawer/ContactButton";
import JTCAButton from "../../parts/MainDrawer/JTCAButton";
import JACAButton from "../../parts/MainDrawer/JACAButton";
import JHMAButton from "../../parts/MainDrawer/JHMAButton";
import {useHistory, useLocation} from "react-router-dom";
import accountIcon from '../../assets/icons/account.png';
import playIcon from '../../assets/icons/play.png';
import eventIcon from '../../assets/icons/event.png';
import snsIcon from '../../assets/icons/sns.png';
import bellIcon from '../../assets/icons/bell.png';
import logoutIcon from '../../assets/icons/logout.png';
import exclamationIcon from '../../assets/icons/exclamation.png';
import {useSelector} from "../../store";
import {setIsDrawerOpen} from '../../stores/drawer';
import {useDispatch} from "react-redux";
import Image from "../../parts/Image";
import {removeAuthenticate} from "../../stores/auth";

const Icon = styled(Image)`
  width: 16px;
  height: 16px;
`;

const Container = styled.div<{ isOpen: boolean }>`
  flex: 1;
  background-color: ${({theme}) => theme.mainColor};
  position: fixed;
  width: 100%;
  right: ${({isOpen}) => isOpen ? '0' : '-100%'};
  left: ${({isOpen}) => isOpen ? '0' : '100%'};
  opacity: ${({isOpen}) => isOpen ? '1' : '0'};
  top: 68px;
  bottom: 0;
  overflow-y: auto;
  transition: all 0.2s;
  z-index: 1000;

  @media screen and (min-width: 480px) {
    top: 0;
  }
`;

const ScrollView = styled.div`

`;

const AstickBox = styled.div`
    border-top-color: ${({theme}) => theme.whiteColor};
    border-top-style: solid;
    border-top-width: 2px;
    left: 30px;
    right: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px 50px 0 50px;
    padding: 30px 0 30px 0;
`;


const AstickText = styled.p`
    font-weight: bold;
    font-size: 14px;
    color: ${({theme}) => theme.whiteColor};
`

const MainDrawer: React.FC = () => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const {drawer, auth} = useSelector(state => ({
        drawer: state.drawer,
        auth: state.auth,
    }))

    useEffect(() => {
        dispatch(setIsDrawerOpen(false));
    }, [location, dispatch]);

    const onLogout = useCallback(() => {
        dispatch(removeAuthenticate());
        history.push('/');
    }, [dispatch, history]);

    return (
        <Container isOpen={drawer.isOpen}>
            <ScrollView>
                <DrawerItem title={'会員情報'}
                            onPress={() => history.push('/account')}
                            icon={<Icon src={accountIcon} color={'#FFFFFF'}/>}/>
                <DrawerItem title={'動画視聴'}
                            onPress={() => history.push('/video')}
                            icon={<Icon src={playIcon} color={'#FFFFFF'}/>}/>
                <DrawerItem title={'イベント'}
                            onPress={() => history.push('/event')}
                            icon={<Icon src={eventIcon} color={'#FFFFFF'}/>}/>
                <DrawerItem title={'SNS交流'}
                            onPress={() => history.push('/sns')}
                            icon={<Icon src={snsIcon} color={'#FFFFFF'}/>}/>
                <DrawerItem title={'お知らせ'}
                            onPress={() => history.push('/notices')}
                            icon={<Icon src={bellIcon} color={'#FFFFFF'}/>}/>
                <DrawerItem title={auth.authenticated ? 'ログアウト' : 'ログイン'}
                            onPress={onLogout}
                            icon={<Icon src={logoutIcon} color={'#FFFFFF'}/>}/>
                <DrawerItem title={'利用規約'}
                            onPress={() => history.push('/guide/agreement')}
                            icon={<Icon src={exclamationIcon} color={'#FFFFFF'}/>}/>
                <DrawerItem title={'イベント管理受講ガイドライン '}
                            onPress={() => history.push('/guide/event')}
                            icon={<Icon src={exclamationIcon} color={'#FFFFFF'}/>}/>
                <DrawerItem title={'SNS機能ガイドライン'}
                            onPress={() => history.push('/guide/sns')}
                            icon={<Icon src={exclamationIcon} color={'#FFFFFF'}/>}/>
                <GuideButton onPress={() => history.push('/guide')}/>
                <ContactButton onPress={() => window.open('https://app.chatplus.jp/chat/visitor/1eae23a2_6?t=btn')}/>
                <JTCAButton onPress={() => window.open('https://jtca2020.or.jp')}/>
                <JACAButton onPress={() => window.open('https://jaca2021.or.jp')}/>
                <JHMAButton onPress={() => window.open('https://jhma.or.jp')}/>
                <AstickBox>
                    <AstickText>アステッキホールディングス</AstickText>
                </AstickBox>
            </ScrollView>
        </Container>
    );
};

export default MainDrawer;
