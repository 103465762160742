import React,{useContext} from 'react';
import styled, {ThemeContext} from 'styled-components';
import ArrowIcon from '../ArrowIcon';
import longLogo from '../../assets/jhma_logo_long.png';
import TouchableOpacity from '../TouchableOpacity';

const Button = styled(TouchableOpacity)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${({theme}) => theme.jhmaColor};
  border-radius: 2px;
  margin: 24px 24px 24px;
  padding: 8px;
  position: relative;
`;

const Image = styled.img`
  width: 273px;
  height: 50px;
`;

const ArrowIconRight = styled(ArrowIcon)`
  position: absolute;
  right: 16px;
`;

interface Props {
    onPress: () => void;
}

const JHMAButton: React.FC<Props> = ({onPress}) => {
    const theme = useContext(ThemeContext);

    return (
        <Button onClick={onPress}>
            <Image src={longLogo}/>
            <ArrowIconRight size={16} color={theme.mainColor}/>
        </Button>
    )
};

export default JHMAButton;
